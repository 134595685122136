import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FreeApp from './components/FreeApp';
import FreeChart from './components/FreeChart';
import FreeChartUS from './components/FreeChartUS';
import PaidChart from './components/PaidChart';
import PaidChartUS from './components/PaidChartUS';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import NotFound from './components/NotFound';
const App = () => {
  return (
    <Router>
	      <div>
        <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/freeapp" element={<FreeApp />} />
        <Route path="/freechart" element={<FreeChart />} />
		<Route path="/freechartus" element={<FreeChartUS />} />
		<Route path="/paidchart" element={<PaidChart />} />
		<Route path="/paidchartus" element={<PaidChartUS />} />
		<Route path="*" element={<NotFound />} />
      </Routes>
	        </div>
    </Router>
  );
};

export default App;