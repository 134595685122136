import React, { useEffect, useState } from 'react';

const FreeChart = () => {
const [, setAppData] = useState([]);
  const [visibleApps, setVisibleApps] = useState(20);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchDataAndRender();
    updateDocumentTitle();
  }, []);

  const fetchDataAndRender = async () => {
    try {
      const response = await fetch('https://whatshub.top/itunes/freeappcn');
      const jsonData = await response.json();
      setData(jsonData.feed.results);
      const processedData = jsonData.feed.results.map((appinfo, index) => ({
        ...appinfo,
        title: appinfo.name,
        image: appinfo.artworkUrl100,
        appstore_link: appinfo.url,
      }));
      setAppData(processedData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const renderChart = () => {
    if (!data) return null;

    return data.slice(0, visibleApps).map((appinfo, index) => {
      return (
        <div key={index} className="free-app-item" onClick={() => window.open(appinfo.url, '_blank')} role="button">
          <img src={appinfo.artworkUrl100} alt={appinfo.name} loading="lazy" />
          <p>
            {index + 1}. {appinfo.name}
          </p>
        </div>
      );
    });
  };

  const loadMore = () => {
    setVisibleApps(prevVisible => prevVisible + 20);
  };

  const updateDocumentTitle = () => {
    document.title = "AppStore国区免费榜 - GetApp";
  };

  return (
    <div className="free-app-container">
      <div className="free-app-list">
        {renderChart()}
      </div>
      <button className="load-more-button" onClick={loadMore}>
        Load More
      </button>
    </div>
  );
};

export default FreeChart;
