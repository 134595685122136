import React, { useState, useEffect } from 'react';

const FreeApp = () => {
  const [appData, setAppData] = useState([]);
  const [visibleApps, setVisibleApps] = useState(20);

  useEffect(() => {
    updateDocumentTitle();

    fetch('https://app.whatshub.top/free-app')
      .then(response => response.json())
      .then(data => {
        setAppData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const loadMore = () => {
    setVisibleApps(prevVisible => prevVisible + 20);
  };

  const updateDocumentTitle = () => {
    document.title = "AppStore限免APP - GetApp";
  };

  return (
    <div className="free-app-container">
      <div className="free-app-list">
        {appData.slice(0, visibleApps).map((app, index) => (
          <div key={index} className="free-app-item" onClick={() => window.open(app.appstore_link, '_blank')} role="button">
            <img src={app.image} alt={`${app.title} App`} loading="lazy" />
            <p>{app.title}</p>
            <p>{app.pub_date}</p>
          </div>
        ))}
      </div>
      {visibleApps < appData.length && (
        <button className="load-more-button" onClick={loadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

export default FreeApp;
